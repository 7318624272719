import { useLayoutEffect, useState } from 'react';

const useGridMaxHeight = (gutterSpace = 200, minHeight = 300) => {
  const [maxHeight, setMaxHeight] = useState<number>(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      const maxHt = Math.max(window.innerHeight - gutterSpace, minHeight);
      setMaxHeight(maxHt);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return maxHeight;
};

export default useGridMaxHeight;
