/* eslint-disable class-methods-use-this */
import * as React from 'react';
import classNames from 'classnames';
import {
  Cell,
  CellTemplate,
  Compatible,
  getCellProperty,
  getCharFromKey,
  isAlphaNumericKey,
  keyCodes,
  Uncertain,
  UncertainCompatible,
} from '../reactgrid';

import EditModeCellWrap from './EditModeCellWrap';
import {
  getMultiSelectSelectedOptions,
  getMultiSelectText,
} from '../cell_utils';
import DropdownInput from './DropdownInput';
import { GetModifiedRowFunc, GridSelectOptionType } from '../types';
import { MULTI_SELECT_SEPARATOR } from '../constants';

export interface MultiSelectCell extends Cell {
  columnId: string;
  rowId: number;
  type: 'multiSelect';
  selectedValue?: string[];
  filterText?: string;
  values: GridSelectOptionType[];
  isDisabled?: boolean;
  getModifiedRow?: GetModifiedRowFunc;
  isActive?: boolean;
  inputValue?: string;
  isInvalidCell?: boolean;
  errorMessage?: string;
}

type Props = {
  gridRef: any;
};

class MultiSelectCellTemplate implements CellTemplate<MultiSelectCell> {
  private gridRef: any;

  constructor(props: Props) {
    this.gridRef = props.gridRef;
  }

  getCompatibleCell(
    uncertainCell: Uncertain<MultiSelectCell>,
  ): Compatible<MultiSelectCell> {
    let selectedValue: string[] | undefined;
    let columnId: string | undefined;
    let rowId: number | undefined;

    try {
      selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'object');
    } catch {
      selectedValue = [];
    }

    const values = getCellProperty(uncertainCell, 'values', 'object');
    const value = selectedValue
      ? parseFloat(selectedValue?.join(MULTI_SELECT_SEPARATOR))
      : NaN;

    let inputValue: string | undefined;
    try {
      inputValue = getCellProperty(uncertainCell, 'inputValue', 'string');
    } catch {
      inputValue = undefined;
    }

    let isActive: boolean;
    try {
      isActive = getCellProperty(uncertainCell, 'isActive', 'boolean');
    } catch {
      isActive = false;
    }

    try {
      columnId = getCellProperty(uncertainCell, 'columnId', 'string');
    } catch {
      columnId = '';
    }
    try {
      rowId = getCellProperty(uncertainCell, 'rowId', 'number');
    } catch {
      rowId = 0;
    }

    const text = getMultiSelectText(selectedValue, uncertainCell);

    return {
      ...uncertainCell,
      selectedValue,
      text,
      value,
      values,
      isActive,
      columnId,
      rowId,
      inputValue,
    };
  }

  update(
    cell: Compatible<MultiSelectCell>,
    cellToMerge: UncertainCompatible<MultiSelectCell>,
  ): Compatible<MultiSelectCell> {
    if (cell.isDisabled) {
      return this.getCompatibleCell({ ...cell });
    }
    const selectedOptions = getMultiSelectSelectedOptions(
      cellToMerge.text,
      cell.values,
    );

    return this.getCompatibleCell({
      ...cell,
      selectedValue: selectedOptions.map((x) => x.value),
      inputValue: cellToMerge.inputValue,
    });
  }

  getClassName(
    cell: Compatible<MultiSelectCell>,
    _isInEditMode: boolean,
  ): string {
    const cellValue = cell.selectedValue || '';
    const className = cell.className ? cell.className : '';
    const isMissingData = cellValue === '';

    return classNames(className, {
      'rg-disabled': cell.isDisabled,
      'rg-invalid': cell.isInvalidCell,
      'rg-invalid-missing-data': isMissingData,
      valid: !cell.isInvalidCell,
    });
  }

  handleKeyDown(
    cell: Compatible<MultiSelectCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
    key: string,
    capsLock: boolean,
  ): { cell: Compatible<MultiSelectCell>; enableEditMode: boolean } {
    if ((keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER) && !shift) {
      return {
        cell: this.getCompatibleCell({ ...cell }),
        enableEditMode: false,
      };
    }

    const char = getCharFromKey(key, shift, capsLock);

    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE)
    )
      return {
        cell: this.getCompatibleCell({
          ...cell,
          inputValue: char,
        }),
        enableEditMode: false,
      };

    return { cell, enableEditMode: false };
  }

  handleCompositionEnd(
    cell: Compatible<MultiSelectCell>,
    eventData: any,
  ): { cell: Compatible<MultiSelectCell>; enableEditMode: boolean } {
    return {
      cell: { ...cell, inputValue: eventData },
      enableEditMode: false,
    };
  }

  render(
    cell: Compatible<MultiSelectCell>,
    _isInEditMode: boolean,
    onCellChanged: (cell: Compatible<MultiSelectCell>, commit: boolean) => void,
  ): React.ReactNode {
    const cellId = `${cell.rowId}-${cell.columnId}`;
    return (
      <EditModeCellWrap
        isInvalidCell={cell.isInvalidCell}
        errorMessage={cell.errorMessage}
        cellValue={cell.selectedValue || []}
        cellId={cellId}
      >
        <div
          style={{ width: '100%', height: '100%' }}
          onPointerDown={(e) => {
            e.stopPropagation();
            if (this.gridRef?.current) {
              this.gridRef.current.focusOnCell(e);
            }
          }}
        >
          <DropdownInput
            onCellChanged={(newCell: Compatible<MultiSelectCell>) => {
              onCellChanged(this.getCompatibleCell(newCell), true);
            }}
            cell={cell}
            isMultiSelect
          />
        </div>
      </EditModeCellWrap>
    );
  }
}

export default MultiSelectCellTemplate;
