import { Box, Heading, Stack, Text } from '@angellist/adapt';
import React from 'react';
import { HealthCheck } from '../../../../graphql';
import { getDegreeColor } from '../utils';

type Props = {
  healthChecks: HealthCheck[];
};

type HealthCheckItemProps = {
  healthCheck: HealthCheck;
};

const HealthCheckItem = (props: HealthCheckItemProps) => {
  const { healthCheck } = props;
  const backgroundColor = getDegreeColor(healthCheck.degree);

  return (
    <Stack direction="horizontal" gap="75" align="center">
      <Box
        width="50"
        height="50"
        borderRadius="full"
        backgroundColor={backgroundColor}
      />
      <Text size="200">{healthCheck.title}</Text>
    </Stack>
  );
};

const HealthCheckTooltipContext = (props: Props) => {
  const { healthChecks } = props;
  if (healthChecks.length > 1) {
    return (
      <Box display="flex" gap="75" width="1600" flexDirection="column">
        <Heading size="50" color="text70">
          Health Checks
        </Heading>
        <Stack gap="75">
          {healthChecks.map((healthCheck) => (
            <HealthCheckItem key={healthCheck.id} healthCheck={healthCheck} />
          ))}
        </Stack>
      </Box>
    );
  }

  return <HealthCheckItem healthCheck={healthChecks[0]} />;
};

export default HealthCheckTooltipContext;
