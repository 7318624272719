/* eslint-disable class-methods-use-this */
import * as React from 'react';
import classNames from 'classnames';
import {
  Cell,
  CellTemplate,
  Compatible,
  getCellProperty,
  DropdownCellTemplate as GridDropdownCellTemplate,
  Uncertain,
  UncertainCompatible,
} from '../reactgrid';

import EditModeCellWrap from './EditModeCellWrap';
import { getDropdownSelectedOption, getDropdownText } from '../cell_utils';
import DropdownInput from './DropdownInput';
import { GetModifiedRowFunc, GridSelectOptionType } from '../types';

// THIS FILE IS A extended from ORIGINAL FILE DropdownCellTemplate.tsx with selection logic fix

export interface DropdownCell extends Cell {
  columnId: string;
  rowId: number;
  type: 'dropdown';
  selectedValue?: string;
  filterText?: string;
  values: GridSelectOptionType[];
  isDisabled?: boolean;
  getModifiedRow?: GetModifiedRowFunc;
  isActive?: boolean;
  inputValue?: string;
  isInvalidCell?: boolean;
  errorMessage?: string;
}

type Props = {
  gridRef: any;
};

// @ts-ignore
class DropdownCellTemplate extends GridDropdownCellTemplate
  implements CellTemplate<DropdownCell> {
  private gridRef: any;

  constructor(props: Props) {
    super();
    this.gridRef = props.gridRef;
  }

  getCompatibleCell(
    uncertainCell: Uncertain<DropdownCell>,
  ): Compatible<DropdownCell> {
    let selectedValue: string | undefined;
    let columnId: string | undefined;
    let rowId: number | undefined;

    try {
      selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'string');
    } catch {
      selectedValue = undefined;
    }

    const values = getCellProperty(uncertainCell, 'values', 'object');
    const value = selectedValue ? parseFloat(selectedValue) : NaN;

    let inputValue: string | undefined;
    try {
      inputValue = getCellProperty(uncertainCell, 'inputValue', 'string');
    } catch {
      inputValue = undefined;
    }

    let isActive: boolean;
    try {
      isActive = getCellProperty(uncertainCell, 'isActive', 'boolean');
    } catch {
      isActive = false;
    }

    try {
      columnId = getCellProperty(uncertainCell, 'columnId', 'string');
    } catch {
      columnId = '';
    }
    try {
      rowId = getCellProperty(uncertainCell, 'rowId', 'number');
    } catch {
      rowId = 0;
    }

    const text = getDropdownText(selectedValue || '', uncertainCell);

    return {
      ...uncertainCell,
      selectedValue,
      text,
      value,
      values,
      isActive,
      columnId,
      rowId,
      inputValue,
    };
  }

  update(
    cell: Compatible<DropdownCell>,
    cellToMerge: UncertainCompatible<DropdownCell>,
  ): Compatible<DropdownCell> {
    if (cell.isDisabled) {
      return this.getCompatibleCell({ ...cell });
    }
    const selectedOption = getDropdownSelectedOption(
      cellToMerge.text,
      cell.values,
    );

    return this.getCompatibleCell({
      ...cell,
      selectedValue: selectedOption?.value,
      inputValue: cellToMerge.inputValue,
    });
  }

  getClassName(cell: Compatible<DropdownCell>, _isInEditMode: boolean): string {
    const cellValue = cell.selectedValue || '';
    const className = cell.className ? cell.className : '';
    const isMissingData = cellValue === '';

    return classNames(className, {
      'rg-disabled': cell.isDisabled,
      'rg-invalid': cell.isInvalidCell,
      'rg-invalid-missing-data': isMissingData,
      valid: !cell.isInvalidCell,
    });
  }

  render(
    cell: Compatible<DropdownCell>,
    _isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DropdownCell>, commit: boolean) => void,
  ): React.ReactNode {
    const cellId = `${cell.rowId}-${cell.columnId}`;
    return (
      <EditModeCellWrap
        isInvalidCell={cell.isInvalidCell}
        errorMessage={cell.errorMessage}
        cellValue={cell.selectedValue || ''}
        cellId={cellId}
      >
        <div
          style={{ width: '100%', height: '100%' }}
          onPointerDown={(e) => {
            e.stopPropagation();
            if (this.gridRef?.current) {
              this.gridRef.current.focusOnCell(e);
            }
          }}
        >
          <DropdownInput
            onCellChanged={(newCell: Compatible<DropdownCell>) =>
              onCellChanged(this.getCompatibleCell(newCell), true)
            }
            cell={cell}
          />
        </div>
      </EditModeCellWrap>
    );
  }
}

export default DropdownCellTemplate;
