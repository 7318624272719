import { Box } from '@angellist/adapt';
import React, { ReactNode } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

type Props = {
  children: ReactNode | string;
  cellValue: string | number | string[];
  cellId: string;
  isInvalidCell?: boolean;
  errorMessage?: string;
};

const EditModeCellWrap = (props: Props) => {
  const { children, isInvalidCell, errorMessage, cellValue, cellId } = props;
  if (isInvalidCell) {
    let errorClass = '';
    let tooltipClass = '';
    if (
      cellValue === '' ||
      cellValue === null ||
      cellValue === undefined ||
      (cellValue instanceof Array && cellValue.length === 0) ||
      (!cellValue && cellValue !== 0)
    ) {
      errorClass = 'rg-cell-missing-data-corner';
      tooltipClass = 'rg-cell-tooltip-missing-data';
    } else {
      errorClass = 'rg-cell-error-corner';
      tooltipClass = 'rg-cell-tooltip-error';
    }
    const elementId = `rg-cell-error-${cellId}`;
    return (
      <Box className="rg-cell-error-wrap">
        {children}
        <Box className={errorClass} id={elementId} />
        <UncontrolledTooltip
          className={`rg-cell-tooltip-wrap ${tooltipClass}`}
          target={elementId}
        >
          {errorMessage}
        </UncontrolledTooltip>
      </Box>
    );
  }
  return <>{children}</>;
};

export default EditModeCellWrap;
