import React, { useState } from 'react';
import { Modal } from '@angellist/adapt';

import { ModalActions } from '../../../../types';
import {
  GridColumnType,
  GridRowType,
} from '../../../../shared/components/grid_editor/types';
import GridEditor from '../../../../shared/components/grid_editor';
import useGridMaxHeight from '../../../../shared/components/importer/hooks/useGridMaxHeight';

type Props = {
  title: string;
  initialData: GridRowType[];
  columns: GridColumnType[];
  submitting: boolean;
  isLoading?: boolean;
  onSave: (data: any[]) => void;
} & Pick<ModalActions, 'modal' | 'closeModal'>;

const BulkFixHealthCheckModal = (props: Props) => {
  const {
    title,
    initialData,
    columns,
    modal,
    closeModal,
    onSave,
    submitting,
    isLoading,
  } = props;
  const [data, setData] = useState<GridRowType[]>(initialData);
  const gridMaxHeight = useGridMaxHeight(325, 200);

  return (
    <Modal
      header={{
        title,
        pinned: true,
        buttons: [
          {
            key: 'submit',
            label: 'Save',
            variant: 'accent' as 'accent',
            isDisabled: submitting,
            isLoading: submitting,
            onPress: () => onSave(data),
          },
        ],
      }}
      onClose={closeModal}
      isOpen={modal}
      width="full"
    >
      <GridEditor
        data={data}
        setData={setData}
        showRowNumbers
        initialEmptyRowCount={0}
        autoAppendRows={false}
        canAddRows={false}
        // @ts-ignore
        columns={columns}
        maxHeight={gridMaxHeight}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default BulkFixHealthCheckModal;
