import { formatValue } from 'react-currency-input-field';
import {
  GridColumnType,
  GridRowType,
  GridRowValue,
  RowOptions,
  GridSelectOptionType,
} from './types';
import { StringOrNumber } from '../../../types';
import { validateColumn } from './utils';
import { formatNumberWithPrecision, getCurrencySign } from '../../utils/number';
import { MAX_ALLOWED_DECIMALS, MULTI_SELECT_SEPARATOR } from './constants';
import { formatDateInMDY, isInvalidDate } from '../../utils/date';

export const getPastedDate = (text: string, value: Date | string | number) => {
  let date: Date | undefined;
  if (text !== '' && !Number.isNaN(text)) {
    date = new Date(text);
  } else if (value !== '' && !Number.isNaN(value)) {
    date = new Date(value);
  }

  if (date && date instanceof Date && !isInvalidDate(date)) {
    return date;
  }
  return undefined;
};

export const sanitizeValue = (value: any) => {
  if (typeof value === 'string') {
    return value?.replace(/[\n\r\t]+/g, ' ')?.trim();
  }
  return value;
};

export const getDropdownSelectedOption = (
  value: string | number,
  items: GridSelectOptionType[],
) => {
  const lowerCellValue = sanitizeValue(value)?.toLowerCase();

  return items.find(
    (item) =>
      item.label.toString().toLowerCase() === lowerCellValue ||
      String(item.value).toLowerCase() === lowerCellValue,
  );
};

export const getMultiSelectSelectedOptions = (
  value: string,
  items: GridSelectOptionType[],
) => {
  const lowerCellValues: string[] =
    sanitizeValue(value)
      ?.toLowerCase()
      ?.split(MULTI_SELECT_SEPARATOR) || [];

  return lowerCellValues
    .map((lowerCellValue: string) =>
      items.find(
        (item) =>
          item.label.toString().toLowerCase() === lowerCellValue ||
          String(item.value).toLowerCase() === lowerCellValue,
      ),
    )
    .filter((x) => !!x);
};

export const getDropdownText = (value: string | number, cell: any) =>
  getDropdownSelectedOption(value, cell.values)?.label;

export const getMultiSelectText = (ids: string[], cell: any) =>
  ids?.length
    ? cell.values
        .filter((item: GridSelectOptionType) => ids.includes(item.value))
        .map((item: GridSelectOptionType) => item.label)
        .join(MULTI_SELECT_SEPARATOR)
    : '';

export const getDateText = (date: Date | undefined) => {
  const value = date?.getTime();
  return Number.isNaN(value) || !value ? '' : formatDateInMDY(date);
};

export const getNumberText = (value: number | undefined, cell: any) =>
  Number.isNaN(value) ||
  (cell.hideZero && value === 0) ||
  value === undefined ||
  (!value && value !== 0)
    ? ''
    : `${cell.prefix || ''}${formatNumberWithPrecision(
        value,
        cell.decimalsLimit ?? MAX_ALLOWED_DECIMALS,
      )}${cell.suffix || ''}`;

export const getMoneyText = (value: number | undefined, cell: any) => {
  const currencySign = cell.currency ? getCurrencySign(cell.currency) : '';
  return Number.isNaN(value) ||
    (cell.hideZero && value === 0) ||
    value === undefined ||
    (!value && value !== 0)
    ? ''
    : formatValue({
        value: Number(value).toString(),
        groupSeparator: ',',
        decimalSeparator: '.',
        prefix: currencySign,
      });
};

const getValidCellProps = (
  column: GridColumnType,
  row: any,
  val: string | number | string[],
  rowOptions?: RowOptions,
) => {
  const { isDirtyRow } = rowOptions;
  if (isDirtyRow && column?.validators?.length) {
    const errorMessage = validateColumn(column?.validators, val, row);
    return {
      isInvalidCell: typeof errorMessage === 'string',
      errorMessage,
    };
  }

  return {
    isInvalidCell: false,
    errorMessage: '',
  };
};

const getRowNumberCell = (column: GridColumnType, rowOptions: RowOptions) => ({
  type: 'nonEditable',
  text: `${rowOptions.rowIndex + 1}`,
  className: `${column.className} rg-rowNumber-cell`,
});

const getRowActionCell = (
  column: GridColumnType,
  row: GridRowType,
  rowOptions: RowOptions,
) => {
  const { rowId, getRowActionMenuItems, isDirtyRow, rowIndex } = rowOptions;

  return {
    type: 'rowAction',
    columnId: column.key,
    rowId,
    actionMenuItems: getRowActionMenuItems(row, {
      rowId,
      isDirtyRow,
      rowIndex,
    }),
    className: `${column.className} rg-rowAction-cell`,
  };
};

const getDropdownCell = (
  column: GridColumnType,
  row: GridRowType,
  rowOptions: RowOptions,
) => {
  const value = row[column.key] as StringOrNumber;
  const validateProps = getValidCellProps(column, row, value, rowOptions);
  const { activeSelectData, rowId } = rowOptions;
  const isActive =
    activeSelectData.rowId === rowOptions.rowId &&
    activeSelectData.columnId === column.key;

  const cell = {
    type: 'dropdown',
    selectedValue: value || '',
    values: column.items || [],
    isActive,
    isDisabled: column.isDisabled ? column.isDisabled(value, row) : false,
    getModifiedRow: column.getModifiedRow,
    className: column.className,
    columnId: column.key,
    maxLength: column.maxLength,
    rowId,
    inputValue: isActive ? activeSelectData.inputValue || '' : '',
    filterText: '',
    ...validateProps,
  };
  cell.filterText = getDropdownText(cell.selectedValue, cell);
  return cell;
};

const getMultiSelectCell = (
  column: GridColumnType,
  row: GridRowType,
  rowOptions: RowOptions,
) => {
  const value = row[column.key] as string[];
  const validateProps = getValidCellProps(column, row, value, rowOptions);
  const { activeSelectData, rowId } = rowOptions;
  const isActive =
    activeSelectData.rowId === rowOptions.rowId &&
    activeSelectData.columnId === column.key;

  const cell = {
    type: 'multiSelect',
    selectedValue: value || [],
    values: column.items || [],
    isActive,
    isDisabled: column.isDisabled ? column.isDisabled(value, row) : false,
    getModifiedRow: column.getModifiedRow,
    className: column.className,
    columnId: column.key,
    maxLength: column.maxLength,
    rowId,
    inputValue: isActive ? activeSelectData.inputValue || '' : '',
    filterText: '',
    ...validateProps,
  };
  cell.filterText = getMultiSelectText(cell.selectedValue, cell);
  return cell;
};

const getDateCell = (
  column: GridColumnType,
  row: GridRowType,
  rowOptions: RowOptions,
) => {
  const value = row[column.key] as string | undefined;
  const validateProps = getValidCellProps(column, row, value, rowOptions);
  const [year1, month1, day1] = value
    ? value.split('-').map((v) => Number.parseInt(v, 10))
    : [undefined, undefined, undefined];

  const cell = {
    type: 'date',
    date: value ? new Date(year1, month1 - 1, day1) : undefined,
    className: column.className,
    columnId: column.key,
    rowId: rowOptions.rowId,
    ...validateProps,
    isDisabled: column.isDisabled ? column.isDisabled(value, row) : false,
    getModifiedRow: column.getModifiedRow,
    filterText: '',
  };
  cell.filterText = getDateText(cell.date);

  return cell;
};

const getCheckboxCell = (column: GridColumnType, row: GridRowType) => {
  const value = row[column.key] as boolean | undefined;

  return {
    type: 'checkbox',
    checked: !!value,
    className: column.className,
    checkedText: column.checkedText,
    uncheckedText: column.uncheckedText,
  };
};

const getNumberCell = (
  column: GridColumnType,
  row: GridRowType,
  rowOptions: RowOptions,
) => {
  const value = row[column.key] as number | undefined;
  const validateProps = getValidCellProps(column, row, value, rowOptions);
  const cell = {
    type: 'number',
    value,
    format: column.format,
    nanToZero: column.nanToZero,
    hideZero: column.hideZero,
    className: column.className,
    columnId: column.key,
    allowNegative: column.allowNegative,
    allowLeadingZeros: column.allowLeadingZeros,
    maxLength: column.maxLength,
    decimalsLimit: column.decimalsLimit,
    prefix: column.prefix,
    suffix: column.suffix,
    rowId: rowOptions.rowId,
    filterText: '',
    isDisabled: column.isDisabled ? column.isDisabled(value, row) : false,
    getModifiedRow: column.getModifiedRow,
    ...validateProps,
  };
  cell.filterText = getNumberText(value, cell);

  return cell;
};

const getMoneyCell = (
  column: GridColumnType,
  row: GridRowType,
  rowOptions: RowOptions,
) => {
  const value = row[column.key] as number | undefined;
  const currency = column.currencyKeyField
    ? row[column.currencyKeyField] || column.currency
    : column.currency;
  const validateProps = getValidCellProps(column, row, value, rowOptions);

  const cell = {
    type: 'money',
    value,
    className: column.className,
    columnId: column.key,
    decimalsLimit: column.decimalsLimit,
    currency,
    rowId: rowOptions.rowId,
    hideZero: column.hideZero,
    filterText: '',
    isDisabled: column.isDisabled ? column.isDisabled(value, row) : false,
    getModifiedRow: column.getModifiedRow,
    ...validateProps,
  };

  cell.filterText = getMoneyText(value, cell);

  return cell;
};

const getDefaultCell = (
  column: GridColumnType,
  row: GridRowType,
  rowOptions: RowOptions,
) => {
  const value = row[column.key] as string;
  const validateProps = getValidCellProps(column, row, value, rowOptions);
  const text = value ? value.toString() : '';

  return {
    type: column.type || 'text',
    columnId: column.key,
    className: column.className,
    rowId: rowOptions.rowId,
    filterText: text,
    text,
    isDisabled: column.isDisabled ? column.isDisabled(value, row) : false,
    getModifiedRow: column.getModifiedRow,
    ...validateProps,
  };
};

export const getCells = (
  columns: GridColumnType[],
  row: any,
  rowOptions: RowOptions,
) =>
  columns.map((column: GridColumnType) => {
    if (column.type === 'dropdown') {
      return getDropdownCell(column, row, rowOptions);
    }
    if (column.type === 'multiSelect') {
      return getMultiSelectCell(column, row, rowOptions);
    }
    if (column.type === 'date') {
      return getDateCell(column, row, rowOptions);
    }
    if (column.type === 'checkbox') {
      return getCheckboxCell(column, row);
    }
    if (column.type === 'number') {
      return getNumberCell(column, row, rowOptions);
    }
    if (column.type === 'money') {
      return getMoneyCell(column, row, rowOptions);
    }
    if (column.type === 'rowNumber') {
      return getRowNumberCell(column, rowOptions);
    }
    if (column.type === 'rowAction') {
      return getRowActionCell(column, row, rowOptions);
    }

    return getDefaultCell(column, row, rowOptions);
  });

export const getEmptyCellValue = (column: GridColumnType): GridRowValue => {
  if (column.type === 'checkbox') {
    return false;
  }
  if (column.type === 'money' || column.type === 'number') {
    return undefined;
  }
  if (column.type === 'dropdown') {
    return '';
  }
  if (column.type === 'multiSelect') {
    return [];
  }
  return '';
};
