/* eslint-disable class-methods-use-this */
import * as React from 'react';
import classNames from 'classnames';

import {
  getCellProperty,
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  inNumericKey,
  isNavigationKey,
  keyCodes,
  DateCellTemplate as GridDateCellTemplate,
  UncertainCompatible,
} from '../reactgrid';
import EditModeCellWrap from './EditModeCellWrap';
import { getDateText, getPastedDate, sanitizeValue } from '../cell_utils';
import { GetModifiedRowFunc } from '../types';

const getFormattedTimeUnit = (timeUnit: number): string =>
  timeUnit.toString().padStart(2, '0');

export interface DateCell extends Cell {
  columnId: string;
  rowId: number;
  type: 'date';
  date?: Date;
  isInvalidCell?: boolean;
  errorMessage?: string;
  filterText?: string;
  isDisabled?: boolean;
  getModifiedRow?: GetModifiedRowFunc;
}

// THIS FILE IS A extended from ORIGINAL FILE DateCellTemplate.tsx with our custom validation logic

// @ts-ignore
class DateCellTemplate extends GridDateCellTemplate
  implements CellTemplate<DateCell> {
  private wasEscKeyPressed = false;

  getCompatibleCell(uncertainCell: Uncertain<DateCell>): Compatible<DateCell> {
    const date = uncertainCell.date
      ? getCellProperty(uncertainCell, 'date', 'object')
      : new Date(NaN);

    let columnId: string | undefined;
    let rowId: number | undefined;
    try {
      columnId = getCellProperty(uncertainCell, 'columnId', 'string');
    } catch {
      columnId = '';
    }
    try {
      rowId = getCellProperty(uncertainCell, 'rowId', 'number');
    } catch {
      rowId = 0;
    }
    const value = date && date instanceof Date ? date?.getTime() : undefined;
    const text = getDateText(date);
    return { ...uncertainCell, date, value, text, columnId, rowId };
  }

  update(
    cell: Compatible<DateCell>,
    cellToMerge: UncertainCompatible<DateCell>,
  ): Compatible<DateCell> {
    if (cell.isDisabled) {
      return this.getCompatibleCell({ ...cell });
    }
    const date = getPastedDate(
      sanitizeValue(cellToMerge.text),
      sanitizeValue(cellToMerge.value),
    );
    return this.getCompatibleCell({
      ...cell,
      date,
    });
  }

  getClassName(cell: Compatible<DateCell>, _isInEditMode: boolean): string {
    const className = cell.className ? cell.className : '';
    const isMissingData = cell.text === '';
    return classNames(className, {
      'rg-disabled': cell.isDisabled,
      'rg-invalid': cell.isInvalidCell,
      'rg-invalid-missing-data': isMissingData,
      valid: !cell.isInvalidCell,
    });
  }

  render(
    cell: Compatible<DateCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DateCell>, commit: boolean) => void,
  ): React.ReactNode {
    if (!isInEditMode) {
      return (
        <EditModeCellWrap
          isInvalidCell={cell.isInvalidCell}
          errorMessage={cell.errorMessage}
          cellValue={cell.text}
          cellId={`${cell.rowId}-${cell.columnId}`}
        >
          {cell.text}
        </EditModeCellWrap>
      );
    }

    if (!cell.date) {
      return `"cell.date" is not initialized with a date value`;
    }

    const year = getFormattedTimeUnit(cell.date.getFullYear());
    const month = getFormattedTimeUnit(cell.date.getMonth() + 1);
    const day = getFormattedTimeUnit(cell.date.getDate());

    return (
      <input
        className="rg-input"
        ref={(input) => {
          if (input) input.focus();
        }}
        type="date"
        defaultValue={`${year}-${month}-${day}`}
        disabled={cell.isDisabled}
        onChange={(e) => {
          if (e.currentTarget.value) {
            const [year1, month1, day1] = e.currentTarget.value
              .split('-')
              .map((v) => Number.parseInt(v, 10));
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                date: new Date(year1, month1 - 1, day1),
              }),
              false,
            );
          }
        }}
        onBlur={(e) => {
          if (e.currentTarget.value) {
            const [year1, month1, day1] = e.currentTarget.value
              .split('-')
              .map((v) => Number.parseInt(v, 10));
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                date: new Date(year1, month1 - 1, day1),
              }),
              !this.wasEscKeyPressed,
            );
            this.wasEscKeyPressed = false;
          }
        }}
        onKeyDown={(e) => {
          if (
            inNumericKey(e.keyCode) ||
            isNavigationKey(e.keyCode) ||
            e.keyCode === keyCodes.COMMA ||
            e.keyCode === keyCodes.PERIOD ||
            ((e.ctrlKey || e.metaKey) && e.keyCode === keyCodes.KEY_A)
          )
            e.stopPropagation();
          if (
            !inNumericKey(e.keyCode) &&
            !isNavigationKey(e.keyCode) &&
            e.keyCode !== keyCodes.COMMA &&
            e.keyCode !== keyCodes.PERIOD
          )
            e.preventDefault();
          if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
      />
    );
  }
}

export default DateCellTemplate;
