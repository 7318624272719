import React from 'react';

import { HealthCheck } from '../../../../graphql';
import { CHECK_TYPES } from '../constant';
import AdaptModalButton from '../../common/AdaptModalButton';
import BulkFixEmploymentTypeModal from './BulkFixEmploymentTypeModal';

type Props = {
  checkType: string;
  healthChecks: HealthCheck[];
  refetchHealthChecks: () => void;
};

const getButtonProps = (checkType: string) => {
  if (checkType === CHECK_TYPES.EMPLOYMENT_TYPE_MISSING) {
    return {
      label: 'Bulk Resolve',
      modalComponent: BulkFixEmploymentTypeModal,
    };
  }

  return {};
};

const BulkFixEmployeeHealthCheckButton = (props: Props) => {
  const { checkType, healthChecks, refetchHealthChecks } = props;

  if (healthChecks?.length) {
    const { label, modalComponent } = getButtonProps(checkType);
    return (
      <AdaptModalButton
        label={label}
        buttonProps={{ variant: 'accent' }}
        modalComponent={modalComponent}
        modalProps={{ healthChecks, refetchHealthChecks }}
      />
    );
  }

  return null;
};

export default BulkFixEmployeeHealthCheckButton;
