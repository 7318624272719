import React, { useEffect } from 'react';
import './styles/styles.scss';
import { Box } from '@angellist/adapt';
import DataEditor from './components/DataEditor';
import { GridEditorProps, GridFilterValueType } from './types';
import { getDefaultEmptyRow } from './utils';
import TopActionBar from './components/TopActionBar';
import { DEFAULT_FILTER_VALUES } from './constants';

type Props = {} & GridEditorProps;

const getGridHeight = (ht: number, showTopActionBar: boolean) => {
  let gridHeight = ht;
  if (ht && showTopActionBar) {
    gridHeight = ht - 65;
  }
  if (gridHeight) {
    gridHeight = Math.max(gridHeight, 200);
  }

  return gridHeight;
};

const GridEditor = (props: Props) => {
  const {
    columns,
    data,
    setData,
    initialEmptyRowCount = 10,
    showTopActionBar = true,
    maxHeight,
    minHeight,
    addRowsCount = 10,
    canAddRows = true,
    ...dataEditorProps
  } = props;

  const [gridFilters, setGridFilters] = React.useState<GridFilterValueType>(
    DEFAULT_FILTER_VALUES,
  );

  const handleOnFilterChange = (values: GridFilterValueType) => {
    setGridFilters(values);
  };

  useEffect(() => {
    if (data.length < initialEmptyRowCount) {
      const defaultEmptyRow = getDefaultEmptyRow(columns);
      const emptyRowCount = Math.abs(initialEmptyRowCount - data.length);
      const initialRowData = new Array(emptyRowCount)
        .fill(0)
        .map(() => ({ ...defaultEmptyRow }));
      setData([...data, ...initialRowData]);
    }
  }, []);

  const handleAddRowClick = (numberRows: number = addRowsCount) => {
    const defaultEmptyRow = getDefaultEmptyRow(columns);
    const defaultEmptyRows = new Array(numberRows)
      .fill(0)
      .map(() => ({ ...defaultEmptyRow }));

    setData([...data, ...defaultEmptyRows]);
  };

  const gridMaxHeight = getGridHeight(maxHeight, showTopActionBar);
  const gridMinHeight = getGridHeight(minHeight, showTopActionBar);

  return (
    <Box
      overflow="hidden"
      borderRadius="container"
      borderWidth="default"
      borderColor="border10"
    >
      {showTopActionBar && (
        <TopActionBar
          onAddRowsClick={handleAddRowClick}
          addRowsCount={addRowsCount}
          onFilterChange={handleOnFilterChange}
          canAddRows={canAddRows}
        />
      )}
      <DataEditor
        columns={columns}
        data={data}
        setData={setData}
        maxHeight={gridMaxHeight}
        minHeight={gridMinHeight}
        gridFilters={gridFilters}
        {...dataEditorProps}
      />
    </Box>
  );
};

export default GridEditor;
