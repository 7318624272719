/* eslint-disable class-methods-use-this */
import * as React from 'react';
import { Button, Menu } from '@angellist/adapt';

import {
  Cell,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
  getCellProperty,
} from '../reactgrid';
import { RowActionMenuItem } from '../types';

export interface RowActionCell extends Cell, Span {
  columnId: string;
  rowId: number;
  type: 'RowAction';
  actionMenuItems: RowActionMenuItem[];
}

class RowActionCellTemplate implements CellTemplate<RowActionCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<RowActionCell>,
  ): Compatible<RowActionCell> {
    let columnId: string | undefined;
    let rowId: number | undefined;
    try {
      columnId = getCellProperty(uncertainCell, 'columnId', 'string');
    } catch {
      columnId = '';
    }
    try {
      rowId = getCellProperty(uncertainCell, 'rowId', 'number');
    } catch {
      rowId = 0;
    }

    const actionMenuItems = getCellProperty(
      uncertainCell,
      'actionMenuItems',
      'object',
    );

    return {
      ...uncertainCell,
      columnId,
      actionMenuItems,
      rowId,
      text: rowId.toString(),
      value: rowId,
    };
  }

  render(cell: Compatible<RowActionCell>): React.ReactNode {
    const { actionMenuItems } = cell;

    const handleMenuAction = (key: string) => {
      const menuItem = actionMenuItems.find((item) => item.key === key);
      if (menuItem) {
        menuItem.onPress();
      }
    };

    return (
      <Menu.Button
        triggerElement={
          <Button aria-label="More" icon="more" variant="ghost" />
        }
        placement="bottom end"
        onAction={handleMenuAction}
      >
        {actionMenuItems.map((item) => (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        ))}
      </Menu.Button>
    );
  }

  isFocusable = (): boolean => false;

  getClassName(cell: Compatible<RowActionCell>): string {
    return cell.className ? cell.className : '';
  }
}

export default RowActionCellTemplate;
