import { COUNTRY_NAMES_BY_CODE } from './address/countries';
import { STATES } from './address/states';

// Parses a location, converting country and state codes to their respective names
export const parseLocation = ({
  city,
  stateNameOrCode,
  countryCode,
}: {
  city?: string;
  stateNameOrCode?: string;
  countryCode?: keyof typeof COUNTRY_NAMES_BY_CODE;
}) => {
  const stateNamesByCode = STATES[countryCode as keyof typeof STATES] ?? {};

  const base = {
    city: city?.trim(),
    state:
      stateNamesByCode[stateNameOrCode as keyof typeof stateNamesByCode] ??
      stateNameOrCode?.trim(),
    country: COUNTRY_NAMES_BY_CODE[countryCode],
  };

  return {
    ...base,
    parts: [base.city, base.state, base.country].filter(Boolean),
  };
};
