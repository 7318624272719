/* eslint-disable class-methods-use-this */
import * as React from 'react';
import CurrencyInput from 'react-currency-input-field';
import classNames from 'classnames';
import {
  getCellProperty,
  keyCodes,
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCharFromKey,
  inNumericKey,
  isNavigationKey,
  isAllowedOnNumberTypingKey,
  isCharAllowedOnNumberInput,
  NumberCellTemplate as GridNumberCellTemplate,
  UncertainCompatible,
} from '../reactgrid';
import EditModeCellWrap from './EditModeCellWrap';
import { getCurrencySign } from '../../../utils/number';
import { getMoneyText } from '../cell_utils';
import { GetModifiedRowFunc } from '../types';

export interface MoneyInputCell extends Cell {
  columnId: string;
  rowId: number;
  type: 'number';
  value: number;
  currency?: string;
  currencyKeyField?: string;
  isInvalidCell?: boolean;
  errorMessage?: string;
  decimalsLimit?: number;
  hideZero?: boolean;
  isDisabled?: boolean;
  getModifiedRow?: GetModifiedRowFunc;
}

function parseLocaleNumber(stringNumber: string): number {
  if (!stringNumber.trim()) return NaN;
  const thousandsSeparator = ',';
  const decimalSeparator = '.';
  const normalizedStringNumber = stringNumber.replace(/\u00A0/g, ' '); // Replace non-breaking space with normal space
  const numberString = normalizedStringNumber
    .replace(new RegExp(`[${thousandsSeparator}\\s]`, 'g'), '') // Replace thousands separator and white-space
    .replace(new RegExp(`\\${decimalSeparator}`, 'g'), '.'); // Replace decimal separator

  const trimmedNumberString = numberString.replace(/^(?!-)\D+|\D+$/g, ''); // Remove characters before first and after last number, but keep negative sign
  if (trimmedNumberString === null || trimmedNumberString.trim().length === 0) {
    return NaN;
  }
  return Number(trimmedNumberString);
}

// THIS FILE IS A extended from ORIGINAL FILE NumberCellTemplate.tsx with our custom validation logic

// @ts-ignore
class MoneyInputCellTemplate extends GridNumberCellTemplate
  implements CellTemplate<MoneyInputCell> {
  private wasEscKeyPressed = false;

  getCompatibleCell(
    uncertainCell: Uncertain<MoneyInputCell>,
  ): Compatible<MoneyInputCell> {
    let value: number;
    try {
      value = getCellProperty(uncertainCell, 'value', 'number');
    } catch (error) {
      value = NaN;
    }
    let columnId: string | undefined;
    let rowId: number | undefined;
    try {
      columnId = getCellProperty(uncertainCell, 'columnId', 'string');
    } catch {
      columnId = '';
    }
    try {
      rowId = getCellProperty(uncertainCell, 'rowId', 'number');
    } catch {
      rowId = 0;
    }
    let currency: string | undefined;
    try {
      currency = getCellProperty(uncertainCell, 'currency', 'string');
    } catch {
      currency = 'USD';
    }
    let currencyKeyField: string | undefined;
    try {
      currencyKeyField = getCellProperty(
        uncertainCell,
        'currencyKeyField',
        'string',
      );
    } catch {
      currencyKeyField = undefined;
    }
    let decimalsLimit: number | undefined;
    try {
      decimalsLimit = getCellProperty(uncertainCell, 'decimalsLimit', 'number');
    } catch {
      decimalsLimit = 2;
    }
    const displayValue = Number.isNaN(value) ? undefined : value;
    const text = getMoneyText(displayValue, uncertainCell);
    return {
      ...uncertainCell,
      value: displayValue,
      text,
      columnId,
      rowId,
      currency,
      currencyKeyField,
      decimalsLimit,
    };
  }

  update(
    cell: Compatible<MoneyInputCell>,
    cellToMerge: UncertainCompatible<MoneyInputCell>,
  ): Compatible<MoneyInputCell> {
    if (cell.isDisabled) {
      return this.getCompatibleCell({ ...cell });
    }
    return this.getCompatibleCell({ ...cell, value: cellToMerge.value });
  }

  getClassName(
    cell: Compatible<MoneyInputCell>,
    _isInEditMode: boolean,
  ): string {
    const className = cell.className ? cell.className : '';
    const isMissingData = !(cell.value && cell.value !== 0);
    return classNames(className, {
      'rg-disabled': cell.isDisabled,
      'rg-invalid': cell.isInvalidCell,
      'rg-invalid-missing-data': isMissingData,
      valid: !cell.isInvalidCell,
    });
  }

  render(
    cell: Compatible<MoneyInputCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<MoneyInputCell>, commit: boolean) => void,
  ): React.ReactNode {
    const currencySign = cell.currency ? getCurrencySign(cell.currency) : '';
    if (!isInEditMode) {
      return (
        <EditModeCellWrap
          isInvalidCell={cell.isInvalidCell}
          errorMessage={cell.errorMessage}
          cellValue={cell.value}
          cellId={`${cell.rowId}-${cell.columnId}`}
        >
          {cell.text}
        </EditModeCellWrap>
      );
    }

    return (
      <CurrencyInput
        className="rg-input"
        data-1p-ignore
        prefix={currencySign}
        decimalsLimit={cell.decimalsLimit}
        disabled={cell.isDisabled}
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.value}
        onValueChange={(newValue) => {
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: newValue ? parseLocaleNumber(newValue) : undefined,
            }),
            false,
          );
        }}
        onBlur={(e) => {
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: e.currentTarget.value
                ? parseLocaleNumber(e.currentTarget.value)
                : undefined,
            }),
            !this.wasEscKeyPressed,
          );
          this.wasEscKeyPressed = false;
        }}
        onKeyDown={(e: React.KeyboardEvent) => {
          const isPasteKey =
            (e.ctrlKey || e.metaKey) && e.keyCode === keyCodes.KEY_V;
          const isSelectAllKey =
            (e.ctrlKey || e.metaKey) && e.keyCode === keyCodes.KEY_A;

          if (
            inNumericKey(e.keyCode) ||
            isNavigationKey(e.keyCode) ||
            isAllowedOnNumberTypingKey(e.keyCode) ||
            isSelectAllKey
          )
            e.stopPropagation();
          if (
            !inNumericKey(e.keyCode) &&
            !isNavigationKey(e.keyCode) &&
            !isCharAllowedOnNumberInput(getCharFromKey(e.key)) &&
            !isPasteKey
          )
            e.preventDefault();
          if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
        }}
        onCopy={(e: React.ClipboardEvent) => e.stopPropagation()}
        onCut={(e: React.ClipboardEvent) => e.stopPropagation()}
        onPaste={(e: React.ClipboardEvent) => e.stopPropagation()}
        onPointerDown={(e: React.PointerEvent) => e.stopPropagation()}
      />
    );
  }
}

export default MoneyInputCellTemplate;
