import { gql } from '@apollo/client';

const UPDATE_HEALTH_CHECK = gql`
  mutation UpdateHealthCheck($id: ID!, $status: String!) {
    updateHealthCheck(id: $id, status: $status) {
      id
      title
      displayDescription
      status
      degree
      category
      ownerName
      ownerType
      ownerId
      createdAt
      checkType
      source
      parsedData {
        page
        text
        filename
      }
      enteredValue
    }
  }
`;

export default UPDATE_HEALTH_CHECK;
