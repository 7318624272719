import React from 'react';
import { Tag } from '@angellist/adapt';
import { formatTitle } from '../../helpers/utils';
import { IconLoading } from '@angellist/adapt';

const TAG_INTENT_STATUSES = {
  draft: [
    'draft',
    'no_vested',
    'not_delivered',
    'not_submitted',
    'not_yet_started',
  ],
  new: [],
  neutral: [
    'closedbycustomer',
    'invited',
    'suspectedfraud',
    'created',
    'auditor',
    'clicked',
    'company_live',
    'irs_number',
    'live',
    'not_seen',
    'offer_portal_manager',
    'open',
    'opened',
    'physical',
    'state_filings',
    'stock_issuance',
    'tax_id_number',
    'vesting',
    'virtual',
  ],
  other: ['clearing', 'viewer', 'expired', 'forfeited'],
  info: [],
  success: [
    'approved',
    'editor',
    'lawyer_approved',
    'seen',
    'submitted',
    'succeeded',
    'success',
    'surrendered',
    'uploaded',
  ],
  warning: [
    'awaiting_documents',
    'awaiting_esigns',
    'awaiting_funds',
    'awaiting_signature',
    'awaitingbackimage',
    'awaitingdocuments',
    'awaitingfrontimage',
    'awaitingimages',
    'needs_co_founder_signatures',
    'owner',
    'recommended',
  ],
  danger: [
    'denied',
    'inactive',
    'invalid',
    'priority',
    'required',
    'returned',
    'stolen',
  ],
  inProgress: ['in_progress', 'in_review', 'inprogress', 'processing'],
  pending: [
    'pending',
    'pending_acceptance',
    'pending_approval',
    'pending_issuance',
    'pending_payment',
    'pending_review',
    'pendingreview',
    'requires_company_signature',
  ],
  complete: [
    'complete',
    'completed',
    'delivered',
    'outstanding',
    'raising',
    'externally_executed',
    'fund_recieved',
    'funds_received',
    'sent',
    'signed',
    'vested',
    'active',
  ],
  postComplete: [
    'closed',
    'ended',
    'esop_manager',
    'executed',
    'fully_exercised',
    'repurchased',
    'partially_exercised',
  ],
  canceled: [
    'cancelled',
    'closedbycustomer',
    'failed',
    'frozen',
    'lost',
    'rejected',
  ],
};

const tagStatusMapping = {};

Object.keys(TAG_INTENT_STATUSES).forEach((intent) => {
  TAG_INTENT_STATUSES[intent].forEach((status) => {
    tagStatusMapping[status] = intent;
  });
});

export const STATUS_TAG_INTENT_MAPPING = tagStatusMapping;

export const getStatusTagProps = (status, label, intent) => {
  const tagIntent =
    intent || STATUS_TAG_INTENT_MAPPING[status.toLowerCase()] || 'neutral';
  const tagLabel = label || formatTitle(status);

  return {
    intent: tagIntent,
    label: tagLabel,
  };
};

const LoadingStatusTag = (props) => {
  const {
    status,
    intent,
    label,
    whiteSpace,
    isLoading,
    tooltipContent,
    ...rest
  } = props;
  if (status || label) {
    const { label: tagLabel, intent: tagIntent } = getStatusTagProps(
      status,
      label,
      intent,
    );

    return (
      <Tag intent={tagIntent} whiteSpace={whiteSpace || 'nowrap'} {...rest}>
        <div className="d-flex align-items-center">
          {isLoading && (
            <IconLoading size="75" animation="spin" className="mr-1" />
          )}
          {tagLabel}
        </div>
      </Tag>
    );
  }

  return null;
};

export default LoadingStatusTag;
