import React, { useLayoutEffect, useRef } from 'react';
import { Id, Column } from '../reactgrid';
import { GridColumnType } from '../types';
import { getGridColumns } from '../utils';

const useGridColumnsMetaData = (columns: GridColumnType[]) => {
  const containerRef = useRef<HTMLDivElement>();
  const [gridColumns, setGridColumns] = React.useState<Column[]>(
    getGridColumns(columns),
  );

  useLayoutEffect(() => {
    function updateWidth() {
      const containerWidth = containerRef?.current?.offsetWidth;
      if (containerWidth) {
        const defaultWidth = 150;
        let totalFixedWidth = 0;
        let totalInitialResizableWidth = 0;

        gridColumns.forEach((column, index) => {
          const originalColumn = columns[index];
          const colWidth = originalColumn.width || defaultWidth;
          if (column.resizable) {
            totalInitialResizableWidth += colWidth;
          } else {
            totalFixedWidth += colWidth;
          }
        });
        const totalResizableWidth = containerWidth - totalFixedWidth;

        if (totalInitialResizableWidth && totalResizableWidth > 0) {
          const newColumns = gridColumns.map((column, index) => {
            const originalColumn = columns[index];
            const colWidth = originalColumn.width || defaultWidth;
            const newWidth = column.resizable
              ? Math.max(
                  (colWidth / totalInitialResizableWidth) * totalResizableWidth,
                  colWidth,
                  defaultWidth,
                )
              : colWidth;

            return {
              ...column,
              width: newWidth,
            };
          });
          setGridColumns(newColumns);
        }
      }
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, [containerRef]);

  const onColumnResize = (ci: Id, width: number) => {
    setGridColumns((prevColumns) => {
      const defaultMinWidth = 100;
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = {
        ...resizedColumn,
        width: Math.max(width, defaultMinWidth),
      };
      // eslint-disable-next-line no-param-reassign
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  return {
    containerRef,
    gridColumns,
    onColumnResize,
  };
};

export default useGridColumnsMetaData;
