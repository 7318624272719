import * as React from 'react';
import { FC } from 'react';
import Select, { OptionProps, MenuProps } from 'react-select';

import {
  getDropdownSelectedOption,
  getMultiSelectSelectedOptions,
} from '../cell_utils';
import { GridSelectOptionType } from '../types';

const CustomOption: React.FC<OptionProps<GridSelectOptionType, false>> = ({
  innerProps,
  label,
  isSelected,
  isFocused,
  isDisabled,
}) => (
  <div
    {...innerProps}
    onPointerDown={(e) => e.stopPropagation()}
    className={`rg-dropdown-option${isSelected ? ' selected' : ''}${
      isFocused ? ' focused' : ''
    }${isDisabled ? ' disabled' : ''}`}
  >
    {label}
  </div>
);

const CustomMenu: React.FC<MenuProps<GridSelectOptionType, false>> = ({
  innerProps,
  children,
}: any) => (
  <div
    {...innerProps}
    className="rg-dropdown-menu"
    onPointerDown={(e) => e.stopPropagation()}
  >
    {children}
  </div>
);

const DropdownInput: FC<DIProps> = (props) => {
  const { onCellChanged, cell, isMultiSelect } = props;
  const selectRef = React.useRef<any>(null);

  const [inputValue, setInputValue] = React.useState<string | undefined>(
    cell.inputValue,
  );

  const defaultValue = React.useMemo<
    GridSelectOptionType | GridSelectOptionType[] | undefined
  >(() => {
    if (isMultiSelect) {
      return getMultiSelectSelectedOptions(cell.text, cell.values);
    }
    return getDropdownSelectedOption(cell.text, cell.values);
  }, [cell.text, cell.values]);

  React.useEffect(() => {
    if (cell.isActive && selectRef.current) {
      selectRef.current.focus();
      setInputValue(cell.inputValue);
    }
  }, [cell.isActive, cell.inputValue]);

  return (
    // @ts-ignore
    <Select
      {...(cell.inputValue && {
        inputValue,
        defaultInputValue: inputValue,
        onInputChange: (e) => setInputValue(e),
      })}
      key={`select-${cell.rowId}-${cell.columnId}-${cell.text || 'none'}`}
      isSearchable
      isMulti={isMultiSelect}
      isClearable={false}
      ref={selectRef}
      onChange={(selectedOption) => {
        onCellChanged({
          ...cell,
          selectedValue: isMultiSelect
            ? (selectedOption as GridSelectOptionType[])?.map((s) => s.value)
            : (selectedOption as GridSelectOptionType)?.value,
          isActive: false,
          inputValue: undefined,
        });
      }}
      placeholder="Select"
      blurInputOnSelect
      defaultValue={defaultValue}
      value={defaultValue}
      isDisabled={cell.isDisabled}
      options={cell.values}
      onKeyDown={(e: any) => {
        e.stopPropagation();

        if (e.key === 'Escape') {
          selectRef.current.blur();
          return onCellChanged({
            ...cell,
            isActive: false,
            inputValue: undefined,
          });
        }

        return null;
      }}
      components={{
        Option: CustomOption,
        Menu: CustomMenu,
      }}
      styles={{
        container: (provided) => ({
          ...provided,
          width: '100%',
          height: '100%',
        }),
        control: (provided) => ({
          ...provided,
          border: 'none',
          borderColor: 'transparent',
          minHeight: '25px',
          background: 'transparent',
          boxShadow: 'none',
          height: '100%',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          paddingTop: '0px',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0px 4px',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'inherit',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          marginTop: '4px',
          marginBottom: '4px',
          display: 'none',
        }),
        input: (provided) => ({
          ...provided,
          padding: 0,
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 8px',
          flexWrap: isMultiSelect ? 'nowrap' : 'wrap',
        }),
      }}
    />
  );
};

interface DIProps {
  onCellChanged: (...args: any[]) => void;
  cell: Record<string, any>;
  isMultiSelect?: boolean;
}

export default DropdownInput;
