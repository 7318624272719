import { Filters } from '@angellist/adapt';
import React from 'react';
import { DEFAULT_FILTER_VALUES, FILTER_ITEMS } from '../constants';
import { FilterChangeFunc } from '../types';

type Props = {
  onFilterChange: FilterChangeFunc;
};

const GridEditorFilters = (props: Props) => {
  const { onFilterChange } = props;
  const defaultSelection = DEFAULT_FILTER_VALUES.state;
  const filtersOptions: any[] = [
    { key: 'search', kind: 'search' as 'search', label: 'Search' },
    {
      key: 'state',
      kind: 'selection' as 'selection',
      variant: 'segmented',
      selectionMode: 'single',
      items: FILTER_ITEMS,
    },
  ];

  const processAppliedValues = (values: any) => {
    if (!values) {
      return DEFAULT_FILTER_VALUES;
    }
    let filteredState = defaultSelection;
    const { state, search } = values;
    if (state) {
      filteredState = state.selectedKey;
    }
    const searchText = search?.value?.trim();
    return {
      searchText,
      state: filteredState,
      isFilterApplied: !!(searchText || filteredState !== defaultSelection),
    };
  };

  const handleOnChange = (values: any) => {
    onFilterChange(processAppliedValues(values));
  };

  return (
    <Filters
      defaultApplied={{
        search: { kind: 'search', value: '' },
        state: { selectedKey: defaultSelection },
      }}
      options={filtersOptions}
      onAppliedChange={handleOnChange}
    />
  );
};

export default GridEditorFilters;
