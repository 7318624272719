import * as React from 'react';

function SvgLink(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.25a.75.75 0 01.75.75v4.25a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-3a.5.5 0 00-.5-.5h-3a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5H10z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.207 2.146a.5.5 0 010 .708L6.354 6.707a.5.5 0 01-.708 0l-.353-.353a.5.5 0 010-.708l3.853-3.853a.5.5 0 01.708 0l.353.353z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLink;
