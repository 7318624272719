/* eslint-disable class-methods-use-this */
import * as React from 'react';

import {
  Cell,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
  getCellProperty,
} from '../reactgrid';

export interface NonEditableCell extends Cell, Span {
  type: 'nonEditable';
  text: string;
  renderer?: (text: string) => React.ReactNode;
}

class NonEditableCellTemplate implements CellTemplate<NonEditableCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<NonEditableCell>,
  ): Compatible<NonEditableCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }

  render(cell: Compatible<NonEditableCell>): React.ReactNode {
    const cellText = cell.text || '';
    return cell.renderer ? cell.renderer(cellText) : cellText;
  }

  isFocusable = (): boolean => false;

  getClassName(cell: Compatible<NonEditableCell>): string {
    return cell.className ? cell.className : '';
  }
}

export default NonEditableCellTemplate;
