import { getOptionsNameMap } from '../../helpers/utils';

export const COST_CENTERS = [
  'R&D',
  'Operations',
  'Sales & Marketing',
  'Executives & Officers',
  'Support',
  'Other',
];

export const EMPLOYEE_TYPE_OPTIONS = [
  {
    value: 'full_time',
    label: 'Full time employee',
  },
  {
    value: 'part_time',
    label: 'Part time employee',
  },
  {
    value: 'contractor',
    label: 'Contractor',
  },
  {
    value: 'advisor',
    label: 'Advisor',
  },
  {
    value: 'other_service_provider',
    label: 'Other service provider',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const NEW_SERVICE_PROVIDER_RELATIONSHIP_OPTIONS = [
  {
    value: 'full_time',
    label: 'Full time employee',
  },
  {
    value: 'part_time',
    label: 'Part time employee',
  },
  {
    value: 'contractor',
    label: 'Contractor',
  },
  {
    value: 'advisor',
    label: 'Advisor',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const EMPLOYEE_TYPE_NAME_MAP = getOptionsNameMap(EMPLOYEE_TYPE_OPTIONS);
