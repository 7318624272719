/* eslint-disable react/prop-types */
import React from 'react';
import { Menu } from '@angellist/adapt';
import { useHistory } from 'react-router-dom';

import { healthCheckFixPath } from '../utils';

import useUpdateHealthCheck from '../hooks/useUpdateHealthCheck';

import { HealthCheck } from '../../../../graphql';
import { VoidFunc } from '../../../../types';

type Props = {
  healthCheck: HealthCheck;
  hideFixPath?: boolean;
  refetch?: VoidFunc;
  placement?: string;
  fixPath?: string;
};

const HealthCheckRowActions = ({
  healthCheck,
  refetch,
  fixPath = null,
  placement = null,
  hideFixPath = false,
}: Props) => {
  const { id, status } = healthCheck;
  const { submitting, onUpdate } = useUpdateHealthCheck(id);
  const history = useHistory();

  const handleDeleteClick = async () => {
    await onUpdate('deleted');
    if (refetch) {
      refetch();
    }
  };

  const handleResolveClick = async () => {
    await onUpdate('resolved');
    if (refetch) {
      refetch();
    }
  };

  const onSelection = (key: string) => {
    if (key === 'resolve') {
      handleResolveClick();
    } else if (key === 'ignore') {
      handleDeleteClick();
    } else if (key === 'fix') {
      history.push(fixPath || healthCheckFixPath(healthCheck));
    }
  };

  return (
    <Menu.Button
      containerProps={{ elevation: '100' }}
      icon="more"
      variant="ghost"
      onAction={onSelection}
      placement={(placement || undefined) as any}
      disabledKeys={submitting ? ['resolve', 'ignore', 'fix'] : []}
    >
      <Menu.Item key="ignore">Ignore</Menu.Item>
      {status !== 'resolved' && (
        <Menu.Item key="resolve">Mark as resolved</Menu.Item>
      )}
      {!hideFixPath && <Menu.Item key="fix">Take me to fix</Menu.Item>}
    </Menu.Button>
  );
};

export default HealthCheckRowActions;
