import { gql } from '@apollo/client';

const BULK_FIX_EMPLOYEE_HEALTH_CHECKS = gql`
  mutation BulkFixEmployeeHealthChecks(
    $companyId: ID!
    $employeeAttributes: [EmployeeHealthChecksAttributes!]!
  ) {
    bulkFixEmployeeHealthChecks(
      companyId: $companyId
      employeeAttributes: $employeeAttributes
    )
  }
`;

export default BULK_FIX_EMPLOYEE_HEALTH_CHECKS;
