import React from 'react';
import { Box, TextButton, Tooltip } from '@angellist/adapt';

import { HealthCheck } from '../../../graphql';
import { DEGREE } from './constant';
import { getDegreeColor } from './utils';
import HealthCheckTooltipContext from './components/HealthCheckTooltip';

type Props = {
  healthChecks: HealthCheck[];
};

type DotProps = {
  degreeColor: any;
  zIndex: any;
};

const Dot = (props: DotProps) => {
  const { degreeColor, zIndex } = props;
  return (
    <Box
      width="75"
      height="75"
      borderRadius="full"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="base"
      zIndex={zIndex}
      style={{ marginLeft: '-5px' }}
      className="health-check-dot"
    >
      <Box
        width="50"
        height="50"
        borderRadius="full"
        backgroundColor={degreeColor}
      />
    </Box>
  );
};

const HealthCheckDot = (props: Props) => {
  const { healthChecks } = props;
  if (healthChecks?.length) {
    const hasPriority = healthChecks.some(
      ({ degree }) => degree === DEGREE.PRIORITY,
    );
    const showDot = hasPriority;

    if (showDot) {
      return (
        <Tooltip
          content={<HealthCheckTooltipContext healthChecks={healthChecks} />}
          placement="top"
        >
          <TextButton
            // @ts-ignore
            label={
              <Box
                position="relative"
                display="flex"
                cursor="pointer"
                zIndex="0"
                style={{ paddingLeft: '5px' }}
              >
                {hasPriority && (
                  <Dot
                    zIndex="1"
                    degreeColor={getDegreeColor(DEGREE.PRIORITY)}
                  />
                )}
              </Box>
            }
          />
        </Tooltip>
      );
    }
  }

  return null;
};

export default HealthCheckDot;
