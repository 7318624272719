import { Stack, Text, Progress, Box } from '@angellist/adapt';
import React from 'react';
import { formatNumber } from '../../helpers/utils';

type Props = {
  numerator?: number;
  denominator?: number;
  numeratorText?: string | number;
  denominatorText?: string | number;
  suffixText?: string;
  hideProgress?: boolean;
};

const UnitsColumn = (props: Props) => {
  const {
    denominator,
    denominatorText,
    numerator,
    numeratorText,
    suffixText,
    hideProgress,
  } = props;

  const percentage = Number(
    (((numerator || 0) / (denominator || 1)) * 100).toFixed(2),
  );

  return (
    <Stack gap="50">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        whiteSpace="nowrap"
      >
        <Text size="300">{numeratorText || formatNumber(numerator || 0)}</Text>
        <Text size="300" color="text70">
          &nbsp;/&nbsp;
          {denominatorText || formatNumber(denominator || 0)} {suffixText}
        </Text>
      </Box>
      {!hideProgress && <Progress value={percentage} />}
    </Stack>
  );
};

export default UnitsColumn;
