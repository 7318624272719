import { Box, Button } from '@angellist/adapt';
import React from 'react';
import GridEditorFilters from './GridEditorFilters';
import { FilterChangeFunc } from '../types';

type Props = {
  addRowsCount?: number;
  onAddRowsClick: (numRows?: number) => void;
  onFilterChange: FilterChangeFunc;
  canAddRows: boolean;
};

const TopActionBar = (props: Props) => {
  const {
    onAddRowsClick,
    onFilterChange,
    addRowsCount = 10,
    canAddRows,
  } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="400"
      backgroundColor="surface5"
      padding="100"
    >
      <GridEditorFilters onFilterChange={onFilterChange} />
      {canAddRows && (
        <Button
          size="compact"
          label={addRowsCount > 1 ? 'Add rows' : 'Add row'}
          prefix={'plus' as 'plus'}
          variant="secondary"
          onPress={() => onAddRowsClick(addRowsCount)}
        />
      )}
    </Box>
  );
};

export default TopActionBar;
