import { RowActionMenuFunc, SetDataFunc } from '../types';

const useRowActionsMenu = (
  getRowActionMenuItems: RowActionMenuFunc,
  setData: SetDataFunc,
): RowActionMenuFunc => {
  if (!getRowActionMenuItems) {
    return (_row, rowOptions) => [
      {
        key: 'deleteRow',
        label: 'Delete',
        onPress: () => {
          setData((prevData) =>
            prevData.filter((_item, index) => index !== rowOptions.rowIndex),
          );
        },
      },
    ];
  }

  return getRowActionMenuItems;
};

export default useRowActionsMenu;
