import React, { useMemo } from 'react';
import { useAlert } from 'react-alert';

import { HealthCheck } from '../../../../graphql';
import { ModalActions } from '../../../../types';
import { GridRowType } from '../../../../shared/components/grid_editor/types';
import {
  EMPLOYEE_TYPE_OPTIONS,
  NEW_SERVICE_PROVIDER_RELATIONSHIP_OPTIONS,
} from '../../employees/constants';
import BulkFixHealthCheckModal from './BulkFixHealthCheckModal';
import useFixBulkEmployeeHealthChecks from '../hooks/useFixBulkEmployeeHealthChecks';
import { useCompanyContext } from '../../../context/CompanyContext';

type Props = {
  healthChecks: HealthCheck[];
  refetchHealthChecks: () => void;
} & Pick<ModalActions, 'modal' | 'closeModal'>;

const getColumns = (showStakeholderRelationshipUI: boolean) => [
  {
    label: 'Name',
    key: 'name',
    width: 180,
    isResizable: false,
    type: 'nonEditable' as 'nonEditable',
  },
  {
    label: 'Email',
    key: 'email',
    type: 'nonEditable' as 'nonEditable',
  },
  {
    label: 'Service Type',
    key: 'employeeType',
    type: 'dropdown' as 'dropdown',
    items: showStakeholderRelationshipUI
      ? NEW_SERVICE_PROVIDER_RELATIONSHIP_OPTIONS
      : EMPLOYEE_TYPE_OPTIONS,
  },
];

const BulkFixEmploymentTypeModal = (props: Props) => {
  const { showStakeholderRelationshipUI } = useCompanyContext();
  const { healthChecks, modal, closeModal, refetchHealthChecks } = props;

  const initialData = useMemo(
    () =>
      healthChecks.map((healthCheck) => ({
        id: healthCheck.employee?.id,
        name: healthCheck.employee?.user?.name,
        email: healthCheck.employee?.user?.email || '',
      })),
    [],
  );
  const alert = useAlert();
  const { submitting, onSave } = useFixBulkEmployeeHealthChecks(() => {
    closeModal();
    refetchHealthChecks();
    alert.success('Service provider type updated successfully');
  });

  const handleOnSave = (data: any) => {
    const attributes: any = [];

    data.forEach((row: GridRowType) => {
      if (row.employeeType) {
        attributes.push({
          id: row.id,
          employeeType: row.employeeType,
        });
      }
    });
    if (attributes.length > 0) {
      onSave(attributes);
    } else {
      alert.error('Please select at least one employee to update');
    }
  };

  return (
    <BulkFixHealthCheckModal
      title="Bulk fix service provider type"
      modal={modal}
      closeModal={closeModal}
      submitting={submitting}
      onSave={handleOnSave}
      initialData={initialData}
      // @ts-ignore
      columns={getColumns(showStakeholderRelationshipUI)}
    />
  );
};

export default BulkFixEmploymentTypeModal;
